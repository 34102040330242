<template>
  <v-theme-provider>
    <base-section
      id="bonus"
      class="indigo lighten-5 px-4"
      space="20"
    >
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="10"
            sm="10"
          >
            <base-section-heading
              color="red"
              title="BÔNUS!"
            />

            <base-body space="2">
              <h1 class="mb-3 red--text">
                Antes de conhecer os planos, vamos falar dos <strong>BÔNUS</strong> que você terá inteiramente grátis:
              </h1>
              <div
                v-for="(bonus, i) in bonuses"
                :key="i"
                class="py-4"
              >
                <base-info-card
                  :icon="bonus.icon"
                  :color="bonus.color"
                  :title="`Bônus ${i+1}: ${bonus.title}`"
                  :html="bonus.text"
                  class="black--text"
                />
                <span class="black--text">
                  <b>Valor:</b> {{ bonus.realValue }} | <b>PRA VOCÊ:</b> <span class="red--text">{{ bonus.value }}</span>
                </span>
              </div>
            </base-body>
          </v-col>
          <v-col
            cols="12"
            md="8"
            sm="12"
          >
            <base-body>
              <h1 class="mb-3 red--text">
                Só em bônus, ou seja, o que você está levando DE GRAÇA o valor já passa de <u>R$ 628,88</u>!
              </h1>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>
<script>
  export default {
    name: 'SectionBonus',

    data () {
      return {
        bonuses: [
          {
            title: 'Acompanhamento constante',
            text: 'Contato por WhatsApp pra tirar dúvidas, fazermos ajustes e melhorias infinitos no seu plano alimentar.',
            value: 'R$ 0,00',
            realValue: 'R$ 300,00',
            icon: '',
            color: 'red',
          },
          {
            title: 'Lista de Substituição Dinâmica',
            text: 'Quer variar entre as opções de alimentos do seu plano alimentar, mas sem precisar fazer contas e ficar folhando em páginas de alimentos? Você ganhará a anualidade gratuita do Nutron, o app desenvolvido por mim e minha equipe pra tornar isso simples, rápido e eficiente.',
            value: 'R$ 0,00',
            realValue: 'R$ 179,88',
            icon: '',
            color: 'green',
          },
          {
            title: 'Guia de Mudança de Hábitos',
            text: 'Um livro virtual resumido e direto sobre mudanças de hábitos alimentares, de sono, exercício e mais! Um compilado de informações da Organização Mundial da Saúde (OMS), Revista Britânica de Medicina Esportiva, Universidade de Harvard entre outras instituições.',
            value: 'R$ 0,00',
            realValue: 'R$ 149,00',
            icon: '',
            color: 'indigo',
          },
          {
            title: 'Opinião de Diversos Especialistas',
            text: 'Sou ligado a grupos de estudo e conhecimento contendo profissionais de várias áreas da saúde, como fisioterapia, psiquiatria, ortopedia, endocrinologia, medicina do esporte, educação física e muito mais! Isso facilita pra consultar opiniões de especialistas sobre seu caso e ter recomendações de condutas quando necessário, podendo ter um cuidado ainda maior com sua saúde e resultados.',
            value: 'R$ 0,00',
            realValue: 'IMENSURÁVEL',
            icon: '',
            color: 'amber',
          },
        ],
      }
    },
  }
</script>
